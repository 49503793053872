<style scoped>
.livelist {
  width: 90%;
  margin: 0 auto 10px;
  padding: 10px 20px;
  background: white;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  box-shadow: 0 1px 6px rgb(222 137 115 / 12%);
}
.livelist .liveTitle {
  font-size: 16px;
  font-weight: bold;
  color: rgba(51, 51, 51, 1);
  text-align: left;
  z-index: 999;
  margin-top: 18px;
}
.livelist .bookmark {
  position: absolute;
  left: 0;
  top: 0;
  height: 32px;
  width: 4px;
  border-radius: 3px;
}
.livelist .live-status {
  background: rgb(255, 193, 74);
  padding: 0 20px;
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  color: white;
  /* border-top-left-radius: 3px; */
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  /* border-bottom-left-radius: 3px; */
  letter-spacing: 2px;
  margin-bottom: 10px;
}
.livelist .live-status-grey {
  background: rgb(219, 219, 219);
}
.livelist .live-status-loading {
  background: rgb(156, 199, 5);
}

.livelist .live-status-end {
  background: rgb(112 170 230);
}
.live-status-left {
  width: 0;
  height: 0;
  border-top: 30px solid rgb(255, 193, 74);
  border-left: 18px solid transparent;
}
.live-status-left-loading {
  border-top: 30px solid rgb(156, 199, 5);
}
.live-status-left-grey {
  border-top: 30px solid rgb(219, 219, 219);
}
.live-status-left-end {
  border-top: 30px solid rgb(112 170 230);
}

.liveTime {
  text-align: left;
  font-size: 14px;
  color: rgb(233, 83, 109);
  margin: 4px 0;
}
.line {
  width: 100%;
  margin: 20px auto;
  height: 1px;
  background: rgb(204 204 204 / 23%);
}
.avatar {
  width: 24px;
  height: 24px;
  margin-top: -3px;
  border-radius: 50%;
}
</style>
<template>
  <div style="">
    <div class="livelist" v-for="(item, index) in liveList" :key="index">
      <div style="display: flex">
        <div class="bookmark" :style="{ background: item.bookmarkColor }"></div>
        <div class="liveTitle">
          {{ item.liveName }}
        </div>
      </div>
      <div class="liveTime">
        <span v-if="item.time">
          <span v-if="item.time != '全天播放'">{{ item.time }} </span>
          <span v-else style="color: #808695">{{ item.time }} </span>
        </span>
        <span v-else style="color: #ccc"> 暂无详细时间 </span>
      </div>

      <div class="line"></div>

      <div style="display: flex; justify-content: space-between">
        <div
          style="
            width: 90px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            text-align: left;
          "
        >
          {{ item.orgDeptName || item.userName }}
        </div>
        <div
          @click="toDetail(item)"
          style="display: flex; justify-content: flex-end; align-items: center"
        >
          <div style="width: 60px; text-align: left; margin-right: 5px">
            开始直播
          </div>
          <svg
            t="1701936676356"
            class="icon"
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="18662"
            width="30"
            height="30"
          >
            <path
              d="M512 1024C228.266667 1024 0 795.733333 0 512S228.266667 0 512 0s512 228.266667 512 512-228.266667 512-512 512z"
              fill="#BD1717"
              p-id="18663"
            ></path>
            <path
              d="M725.333333 512l-341.333333 192V320z"
              fill="#FFFFFF"
              p-id="18664"
            ></path>
          </svg>
        </div>
      </div>

      <div style="display: flex; position: absolute; right: 0; top: -15px">
        <svg
          t="1701936533017"
          class="icon"
          viewBox="0 0 2407 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="16692"
          width="50"
          height="50"
        >
          <path
            d="M0 0s279.617968 147.382949 405.281773 585.691474c72.667389 253.375781 211.558963 375.92466 327.451288 438.265856l1674.763564 0.04267V0H0z"
            fill="#FA9543"
            p-id="16693"
          ></path>
          <path
            d="M849.137428 227.304275h55.300609v438.99125h281.96483v48.516043H849.137428V227.304275zM1254.675223 227.304275H1309.975831v487.464622h-55.300608V227.304275zM1366.641887 227.304275h61.44512l152.204684 426.702226h2.048171l152.247354-426.702226h61.40245l-180.23902 487.464622H1546.795566L1366.641887 227.304275zM1848.64472 227.304275h345.458122v48.473373h-289.47479v163.170931h273.089424v48.516043h-273.089424v178.830903h301.08109v48.516043h-357.021751V227.304275z"
            fill="#FFFFFF"
            p-id="16694"
          ></path>
        </svg>
      </div>
    </div>
    <div
      style="margin: 10px 0; width: 100%; text-align: center; color: #fff"
      v-if="0 == liveList.length"
    >
      暂无符合条件的直播间
    </div>
  </div>
</template>

<script>
/* eslint-disable  */

export default {
  name: "rnContentlist",
  data() {
    return {};
  },

  props: {
    // 内容数据
    liveList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {},
  methods: {
    toDetail(data) {
      this.$emit("toEdit", data);
    },
    formatTen(num) {
      return num > 9 ? num + "" : "0" + num;
    },
    formatDate(updated) {
      const date = new Date(updated);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hour = date.getHours();
      const minute = date.getMinutes();

      const second = date.getSeconds();
      return (
        this.formatTen(month) +
        "月" +
        this.formatTen(day) +
        "日 " +
        this.formatTen(hour) +
        ":" +
        this.formatTen(minute) +
        ":" +
        this.formatTen(second)
      );
    },
    formatTime(date, startTime, endTime) {},
  },
  mounted() {},
  created() {},
};
</script>
