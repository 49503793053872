<style scoped>
.main-containers {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: rgba(248 249 255);
}
.Livetop {
  overflow: hidden;
  width: 100%;
  height: 120px;
  background: rgba(212, 36, 34, 1);
  border-bottom-right-radius: 200px 30px;
  border-bottom-left-radius: 200px 30px;
}

.liveSearch {
  /* background: #fff; */
  width: 90%;
  border-radius: 4px;
  margin: 0 auto;
  padding: 10px 20px;
  color: rgba(243, 183, 180, 1);
  display: flex;
  margin: 10px auto 10px;
  text-align: left;
  box-shadow: inset 0 0 10px 0 hsl(0deg 0% 100% / 41%);
}

.iconColor {
  color: rgba(243, 183, 180, 1);
}
input.liveSearchInput::-webkit-input-placeholder {
  color: rgba(243, 183, 180, 1);
}
.liveSearchInput {
  background: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  color: rgba(243, 183, 180, 1);
}
.myLiveList svg {
  fill: #f1c9c6;
}
</style>

<template>
  <div class="main-containers">
    <div class="main" style="width: 100vw; height: 100vh">
      <div class="Livetop">
        <div
          class="liveSearch"
          style="
            width: 90%;
            margin: 10px auto;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2px;
            position: relative;
            text-align: center;
            color: white;
            font-weight: bold;
          "
        >
          <span
            style="position: absolute; left: 10px; top: 4px"
            @click="$router.back()"
          >
            <svg
              t="1701932820789"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="12453"
              width="32"
              height="32"
            >
              <path
                d="M510.9 60.7c-245.6 0-446.7 199.8-446.7 446.7C64.2 753 263.9 954 510.8 954s446.6-199.7 446.6-446.6c0.1-245.6-199.6-446.7-446.5-446.7zM760.5 694c-0.1 6.3-5.3 11.4-11.7 11.4-5.6 0-10.3-3.9-11.4-9.3-0.2-0.7-7.8-35.2-40.4-69.1-41.5-43.2-105.8-66.9-186.4-69v93.8c0 4.4-2.5 8.3-6.4 10.3-3.9 2-8.6 1.6-12.2-0.9L268.2 500.7c-1-0.7-1.9-1.6-2.6-2.6-3.7-5.2-2.5-12.3 2.6-16L492 321c3.6-2.6 8.3-2.9 12.2-1 3.9 2 6.3 5.9 6.4 10.3v99.6c23.2 0.9 46.3 4.4 68.7 10.3 46.4 12.2 85 33.4 114.6 63.2 45.6 45.6 68 109.8 66.6 190.6z"
                p-id="12454"
                fill="#ffffff"
              ></path>
            </svg>
          </span>

          我的直播间
        </div>
      </div>
      <div
        style="
          margin: -60px auto 40px;
          width: 100vw;
          height: 90vh;
          overflow-y: scroll;
        "
      >
        <live-list-cmp :liveList="liveList" @toEdit="toLive"></live-list-cmp>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable  */
import liveListCmp from "../../widgets/rnContentListRtc.vue";
import { wxConPost } from "../../api/httpApi";
import wxLoginApi from "../../api/wxLoginApi";
import moment from "moment";

import _ from "lodash";

const debug = require("debug")("@main");

export default {
  name: "LiveList",
  data() {
    return {
      liveList: [],
      liveListDay: [],
      recordedList: [],
      timeOutTimer: null,
      userid: "",
      searchlive: "",
    };
  },
  computed: {},
  methods: {
    toLive(detail) {
      this.$router.push({
        path: "/pushRtc",
        query: {
          roomId: detail.liveId,
          roomId: detail.liveId,
          roomId: detail.liveId,
          roomId: detail.liveId,
        },
      });
    },
    geyRgb() {
      //rgb颜色随机
      const r = Math.floor(Math.random() * 256);
      const g = Math.floor(Math.random() * 256);
      const b = Math.floor(Math.random() * 256);
      const rgb = "(" + r + "," + g + "," + b + ")";
      return rgb;
    },
    async getLiveList() {
      try {
        const ret = await wxConPost(
          "/services/gttt-live-mgt/rtcLive/getRtcLiveList"
        );

        if (ret.ret && ret.ret.length > 0) {
          for (const iterator of ret.ret) {
            const data = iterator;
            let time = `${moment(iterator.startTime).format(
              "YYYY-MM-DD HH:mm"
            )} ${moment(iterator.endTime).format("YYYY-MM-DD HH:mm")}`;

            Object.assign(data, {
              time: time,
              liveStatus: 2,
              authorList: [
                { author: iterator.author, avatar: "/img/logo.jpeg" },
              ],
              bookmarkColor: `rgb${this.geyRgb()}`,
            });
            this.liveList.push(data);
          }
          this.$store.state.columnnew = this.liveList;
          debug("222----", this.$store.state.columnnew);
        }
      } catch (error) {
        debug("----", error);
      }
    },
  },
  components: { liveListCmp },
  async created() {
    wxLoginApi.onReady(async () => {
      await this.getLiveList();
    });
  },
  beforeCreate() {
    document.title = "直播列表";
  },
  destroyed() {
    debug("destroyed");
  },
};
</script>
